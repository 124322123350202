// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
    .DayPicker-Day--outside
  ) {
  background-color: #4285f4;
}

.DayPicker-Day--today {
  color: #000;
  font-weight: 400;
}

.DayPicker-Caption { 
  color: #000;
}

.DayPicker-Day{
  color: #000;
}
.DayPicker-Day.DayPicker-Day--disabled{
  color: #dce0e0;
} 
 [data-theme="dark"] .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  color: white;
} 

 [data-theme="dark"] .DayPicker-Caption {
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/components/DatePicker/reactDayPicker.css"],"names":[],"mappings":"AAAA;;;EAGE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb;AACA;EACE,cAAc;AAChB;CACC;EACC,YAAY;AACd;;CAEC;EACC,YAAY;AACd","sourcesContent":[".DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(\n    .DayPicker-Day--outside\n  ) {\n  background-color: #4285f4;\n}\n\n.DayPicker-Day--today {\n  color: #000;\n  font-weight: 400;\n}\n\n.DayPicker-Caption { \n  color: #000;\n}\n\n.DayPicker-Day{\n  color: #000;\n}\n.DayPicker-Day.DayPicker-Day--disabled{\n  color: #dce0e0;\n} \n [data-theme=\"dark\"] .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {\n  color: white;\n} \n\n [data-theme=\"dark\"] .DayPicker-Caption {\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
