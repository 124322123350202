import React, {useRef} from 'react';
import {View, Text, TouchableOpacity, ViewStyle} from 'react-native';
import {observer} from 'mobx-react-lite';
import {useRoot, useStrings} from '../../Root/hooks';
import {Button, PressableOpacity} from '../../components';
import {createStylesHook} from '../../styling';
import {useBoolean} from '../../hooks';
import useOutside from '../../../universal/utils/react/hook/useOutside';
import {
  SimpleAppStoreButton,
  SimpleGooglePlayButton,
} from '../../components/StoreButton';
import {Hoverable} from 'react-native-web-hooks';

export interface ItemAvailableSlotsProps {
  goToSubscriptions: () => void;
  goToPlayStoreSubscriptions: () => void;
  goToAppStoreSubscriptions: () => void;
}

export default observer(
  ({
    goToSubscriptions,
    goToPlayStoreSubscriptions,
    goToAppStoreSubscriptions,
  }: ItemAvailableSlotsProps) => {
    const [popupVisible, showPopup, hidePopup] = useBoolean(false);
    const {currentSubscriptionState} = useRoot();
    const strings = useStrings();
    const styles = useStyles();
    const popupRef = useRef<View>(null);
    useOutside(popupRef, hidePopup, 'click');
    if (!currentSubscriptionState.subscription) {
      return null;
    }
    const {maxSlots, slots} = currentSubscriptionState.subscription;
    return (
      <View style={styles.root}>
        <View style={styles.header}>
          <Text style={styles.title}>{strings['menu.info.totalSlots']}</Text>
          <View>
            <Text style={styles.slotsText}>
              <Text style={styles.usedSlotsText}>{slots}</Text>
              <Text> / </Text>
              <Text style={styles.maxSlotsText}>{maxSlots}</Text>
            </Text>
          </View>
        </View>
        <View style={styles.content}>
          <Hoverable>
            {(isHovered) => (
              <TouchableOpacity onPress={goToSubscriptions} activeOpacity={0.8}>
                <Button
                  textStyle={[
                    styles.buttonText,
                    isHovered && styles.buttonTextHovered,
                  ]}
                  style={[styles.button, isHovered && styles.buttonHovered]}>
                  {strings['menu.addMoreSlots']}
                </Button>
              </TouchableOpacity>
            )}
          </Hoverable>
        </View>
        <Hoverable>
          {(isHovered) => (
            <PressableOpacity
              style={styles.manageSubscription}
              onPress={popupVisible ? hidePopup : showPopup}>
              <View style={styles.footerLinkView}>
                <Text
                  style={[
                    styles.footerLink,
                    isHovered && styles.footerLinkTextHovered,
                  ]}>
                  {strings['menu.manageSubscription']}
                </Text>
              </View>
            </PressableOpacity>
          )}
        </Hoverable>
        {popupVisible && (
          <View style={styles.popup} ref={popupRef}>
            <SimpleGooglePlayButton
              contentStyle={styles.storeButton}
              onPress={goToPlayStoreSubscriptions}
            />
            <SimpleAppStoreButton
              contentStyle={styles.storeButton}
              onPress={goToAppStoreSubscriptions}
            />
          </View>
        )}
      </View>
    );
  },
);

const useStyles = createStylesHook((theme) => ({
  root: {
    zIndex: 2,
    paddingHorizontal: 20,
    paddingVertical: 14,
    borderColor: theme.palette.border,
    borderBottomWidth: 1,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  content: {
    marginBottom: 14,
  },
  footer: {},
  title: {
    ...theme.fontByWeight('700'),
    fontSize: 14,
    lineHeight: 16,
    letterSpacing: 0.04,
    color: theme.chroma(theme.palette.icon).alpha(0.4).hex(),
    marginRight: 10,
  },
  button: {
    paddingVertical: 10,
  },
  buttonText: {
    color: theme.palette.secondary,
  },
  buttonHovered: {
    backgroundColor: theme.palette.primary,
  },
  buttonTextHovered: {
    color: '#ffffff',
  },
  slotsText: {
    ...theme.fontByWeight('normal'),
    fontSize: 15,
    lineHeight: 16,
    letterSpacing: 0.04,
    color: theme.palette.textPrimary,
  },
  usedSlotsText: {
    ...theme.fontByWeight('700'),
    color: theme.palette.success,
  },
  maxSlotsText: {},
  footerLink: {
    ...theme.fontByWeight('normal'),
    fontSize: 12,
    color: theme.palette.textSecondary,
  },
  footerLinkView: {
    cursor: 'pointer',
  } as ViewStyle,
  footerLinkTextHovered: {
    color: theme.palette.primary,
  },
  manageSubscription: {
    marginBottom: 5,
  },
  popup: {
    position: 'absolute',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 15,
    borderWidth: 1,
    borderColor: theme.palette.border,
    top: 120,
    left: 20,
    right: 20,
    borderRadius: 3,
    backgroundColor: theme.palette.background,
    shadowColor: 'rgba(0, 0, 0, 0.3)',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 1,
    shadowRadius: 4,
    transition: 'box-shadow linear 150ms',
  },
  storeButton: {
    width: 77,
    height: 48,
  },
}));
