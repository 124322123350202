import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Linking, Platform, StyleSheet, Text, View} from 'react-native';
import {
  CameraView,
  PermissionResponse,
  BarcodeScanningResult,
  PermissionStatus,
  BarcodeSettings,
  useCameraPermissions,
} from 'expo-camera';
import {useStyles} from '../styling';
import Button, {ButtonColor} from '../components/Button';
import {PressableOpacity} from '../components';
import {useStrings} from '../Root/hooks';

export type CameraProps = {
  onBarCodeScanned: (scanningResult: BarcodeScanningResult) => void;
};

export default observer(({onBarCodeScanned}: CameraProps) => {
  const strings = useStrings();
  const [permission, setPermission] = useState<PermissionResponse>();
  const styles = useStyles((theme) => ({
    cameraView: {
      height: theme.window.width - 40,
      width: theme.window.width - 40,
      marginBottom: 20,
      borderWidth: 1,
      borderColor: theme.palette.border,
    },
    errorCameraView: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    errorMessage: {
      ...theme.fontByWeight('400'),
      marginBottom: 10,
    },
  }));
  const [, requestCameraPermissionsAsync, getCameraPermissionsAsync] =
    useCameraPermissions();
  const request = useCallback(async () => {
    const request_ = await requestCameraPermissionsAsync();
    setPermission(request_);
  }, [requestCameraPermissionsAsync]);
  const handleRequestPermissions = useCallback(async () => {
    if (permission?.canAskAgain) {
      const request_ = await getCameraPermissionsAsync();
      setPermission(request_);
    } else {
      switch (Platform.OS) {
        case 'ios':
          await Linking.openURL('app-settings:');
          break;
        case 'android':
          await Linking.openSettings();
          break;
      }
    }
  }, [getCameraPermissionsAsync, permission]);
  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    request();
  }, [request]);

  const errorMessage = useMemo(() => {
    switch (permission?.status) {
      case PermissionStatus.DENIED:
        return strings['camera.error'];
    }
    return null;
  }, [permission, strings]);

  const camera = useMemo(
    () => (
      <CameraView
        facing="back"
        ratio="1:1"
        style={StyleSheet.absoluteFillObject}
        onBarcodeScanned={onBarCodeScanned}
        barcodeScannerSettings={BAR_CODE_SCANNER_SETTINGS}
      />
    ),
    [onBarCodeScanned],
  );

  return (
    <View style={styles.cameraView}>
      {errorMessage ? (
        <View style={styles.errorCameraView}>
          <Text style={styles.errorMessage}>{errorMessage}</Text>
          <PressableOpacity onPress={handleRequestPermissions}>
            <Button color={ButtonColor.Primary}>
              {strings['camera.allow']}
            </Button>
          </PressableOpacity>
        </View>
      ) : permission ? (
        camera
      ) : null}
    </View>
  );
});

const BAR_CODE_SCANNER_SETTINGS: BarcodeSettings = {
  barcodeTypes: ['qr'],
};
