import {observer} from 'mobx-react-lite';
import React, {useRef, useState} from 'react';
import {Platform, Text, TouchableOpacity, View} from 'react-native';
import {useStrings} from '../../Root/hooks';

import {
  SlotStatus,
  WorkerType,
} from '../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {ListItem} from './ListItem';
import {getWorkerName} from '../../DashboardScreen';
import {createStylesHook, useTheme} from '../../styling';
import minutesToHourMinutes from '../minutesToHourMinutes';
import {WorkerList} from '../types';
import {sized} from '../../Svg';
import CaretDownSvg from '../../assets/svg/colorless/caretDown.svg';
import Markdown from '../../components/Markdown';
import useOutside from '../../../universal/utils/react/hook/useOutside';

export type ScheduleGroupWorkersListProps = {
  workers: WorkerList;
  selectedWorkerIdList: string[];
  onWorkerSelect: (workerId: string) => void;
  timezoneOffset: number;
};

export const WorkersList = observer(
  ({
    workers,
    timezoneOffset,
    selectedWorkerIdList,
    onWorkerSelect,
  }: ScheduleGroupWorkersListProps) => {
    const [open, setOpen] = useState(false);
    const strings = useStrings();
    const styles = useStyles();
    const theme = useTheme();
    const workersTimezones = workers.map((worker) => {
      const differentTimezoneStr = (timezoneOffset - worker.utc_offset) / 60;
      const showTimezone =
        timezoneOffset !== worker.utc_offset &&
        worker.worker_type === WorkerType.Regular;
      return {
        timezone: worker.utc_offset / 60,
        timezoneStr: minutesToHourMinutes(worker.utc_offset),
        differentTimezoneStr:
          differentTimezoneStr < 0
            ? '+' + Math.abs(differentTimezoneStr)
            : '-' + Math.abs(differentTimezoneStr),
        showTimezone: showTimezone,
      };
    });
    const differentTimezoneCount = workersTimezones.filter(
      (wt) => wt.showTimezone,
    ).length;

    const showDifferentTimezone = differentTimezoneCount !== 0;

    const wrapperRef = useRef<View>(null);
    useOutside(wrapperRef, () => setOpen(false));

    const headerText =
      differentTimezoneCount > 1
        ? strings['scheduler.workersHaveADifferentTimezone'].replace(
            '{count}',
            String(differentTimezoneCount),
          )
        : `${differentTimezoneCount} ${strings['scheduler.workerHaveADifferentTimezone']}.`;

    const applyText = strings['scheduler.applyText']
      .replace('{numberOfSelected}', String(selectedWorkerIdList.length))
      .replace('{totalAmount}', String(workers.length));

    return (
      <View style={styles.root}>
        <View style={styles.headerView}>
          {showDifferentTimezone && (
            <Text style={styles.headerText}>{headerText}</Text>
          )}
        </View>
        <View style={styles.content}>
          <TouchableOpacity
            activeOpacity={1}
            style={[styles.openButton, open && styles.opened]}
            onPress={() => setOpen(!open)}>
            <Markdown
              styles={{
                strong: styles.bold,
                paragraph: styles.applyText,
              }}>
              {applyText}
            </Markdown>
            <CaretDownIcon
              style={[styles.closeIcon]}
              color={open ? theme.palette.primary : theme.palette.icon}
            />
          </TouchableOpacity>
          {open && (
            <View style={styles.list} ref={wrapperRef}>
              <View style={styles.listContent}>
                {workers.map((worker, index) => {
                  const disabled =
                    worker.slot_status === SlotStatus.Inactive ||
                    !worker.managed;
                  return (
                    <ListItem
                      key={worker.id}
                      index={index}
                      disabled={disabled}
                      workerTimezone={workersTimezones[index]}
                      active={selectedWorkerIdList.includes(worker.id)}
                      name={getWorkerName(worker)}
                      worker={worker}
                      onPress={() => !disabled && onWorkerSelect(worker.id)}
                    />
                  );
                })}
              </View>
              <TouchableOpacity
                style={styles.hideButton}
                onPress={() => setOpen(false)}>
                <Text style={styles.hideList}>
                  {strings['workerGroup.hideList']}
                </Text>
              </TouchableOpacity>
            </View>
          )}
        </View>
      </View>
    );
  },
);

const CaretDownIcon = sized(CaretDownSvg, 10);

const useStyles = createStylesHook((theme) => ({
  root: {},
  list: {
    zIndex: 2,
    borderWidth: 1,
    borderColor: theme.palette.border,
    marginTop: -1,
    ...Platform.select({
      web: {
        ...theme.mediaQuery({
          1000: {
            position: 'absolute',
            top: '102%',
            zIndex: 10,
          },
        }),
      },
    }),
    ...theme.mediaQuery({
      1000: {
        maxWidth: 350,
        width: '100%',
      },
    }),
  },
  listContent: {
    ...Platform.select({
      web: {
        ...theme.mediaQuery({
          1000: {
            maxHeight: theme.window.height * 0.45,
            overflow: 'scroll',
          },
        }),
      },
    }),
  },
  headerView: {
    marginBottom: 10,
  },
  headerText: {
    ...theme.fontByWeight('700'),
    fontSize: 14,
    lineHeight: 16,
    color: theme.palette.error,
  },
  content: {},
  openButton: {
    ...theme.mediaQuery({
      1000: {
        maxWidth: 350,
      },
    }),
    paddingVertical: 10,
    paddingLeft: 12,
    paddingRight: 20,
    borderWidth: 1,
    borderRadius: 3,
    borderColor: theme.palette.border,
    backgroundColor: theme.palette.background,
  },
  opened: {
    borderColor: theme.palette.primary,
  },
  closeIcon: {
    position: 'absolute',
    top: 15,
    right: 10,
  },
  hideButton: {
    padding: 15,
    backgroundColor: theme.palette.background,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    borderTopWidth: 1,
    borderTopColor: theme.palette.border,
    marginTop: -1,
  },
  hideList: {
    color: theme.palette.secondary,
    flexShrink: 1,
    fontSize: 12,
    ...theme.fontByWeight('700'),
    textTransform: 'uppercase',
  },
  applyText: {
    color: theme.palette.textPrimary,
    letterSpacing: 0.04,
    fontSize: 14,
    ...theme.fontByWeight('400'),
    marginTop: 0,
    marginBottom: 0,
  },
  bold: {
    ...theme.fontByWeight('700'),
  },
}));
