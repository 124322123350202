import React, {useCallback, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useBoolean} from '../hooks';
import {PageScrollView} from '../containers';
import PageHeader from '../components/PageHeader';
import {createStylesHook} from '../styling';
import {useRoot, useStrings} from '../Root/hooks';
import WeekTable from './WeekTable';
import {View} from 'react-native';
import SpeedSwitch from './SpeedSwitch';
import Timezone from './Timezone';
import ScheduleFooter from './ScheduleFooter';
import ScheduleState from './ScheduleState';
import DiscardUnsavedChangesModal from './DiscardUnsavedChangesModal';
import './matrixToIntervals';
import './intervalsToMatrix';
import useBeforeRemoveWatcher from './useBeforeRemoveWatcher';
import useUnsavedDataWatcher from './useUnsavedDataWatcher';
import useScheduleUpdateMessage from './useScheduleUpdateMessage';
import ScheduleHeader from './Header';
import DesktopWebAppHeader from '../DesktopWebAppHeader/DesktopWebAppHeader';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

export interface ScheduleScreenProps {
  workerIds: string[];
  ownerName: string | undefined;
  leave: () => void;
}

export default observer(
  ({workerIds, leave, ownerName}: ScheduleScreenProps) => {
    const root = useRoot();
    const strings = useStrings();
    const [state] = useState(() => new ScheduleState(root, workerIds));
    const [visibleDiscard, showDiscard, hideDiscard] = useBoolean(false);
    const styles = useStyles();

    useEffect(() => state.subscribe(), [state]);

    useBeforeRemoveWatcher(showDiscard, state.numberOfChangedPoints);
    useScheduleUpdateMessage(state.workers);
    useUnsavedDataWatcher(state, leave);

    const onConfirmDiscard = useCallback(() => {
      hideDiscard();
      leave();
    }, [hideDiscard, leave]);

    const insets = useSafeAreaInsets();

    if (state.isLoading || !state.workers.length) {
      return null;
    }

    return (
      <>
        <DesktopWebAppHeader />
        <PageScrollView contentContainerStyle={{paddingBottom: insets.bottom}}>
          <PageHeader
            style={styles.header}
            title={strings['screenTitle.scheduler']}
          />
          <View style={styles.root}>
            <ScheduleHeader state={state} />
            <WeekTable state={state} />
            <SpeedSwitch state={state} />
            <Timezone state={state} />
            <ScheduleFooter state={state} leave={leave} />
          </View>
        </PageScrollView>
        {visibleDiscard && (
          <DiscardUnsavedChangesModal
            ownerName={ownerName}
            onDismiss={hideDiscard}
            onConfirm={onConfirmDiscard}
          />
        )}
      </>
    );
  },
);

const useStyles = createStylesHook((theme) => ({
  root: {
    ...theme.mediaQuery({
      1000: {
        borderWidth: 1,
        borderColor: theme.palette.border,
        borderTopWidth: 0,
      },
      1281: {
        marginBottom: 100,
        borderTopWidth: 1,
      },
    }),
  },
  header: {
    marginVertical: 20,
  },
}));
