import {observer} from 'mobx-react-lite';
import {useRoot, useStrings} from '../../Root/hooks';
import React, {useCallback} from 'react';
import {TextInput, View} from 'react-native';
import {useTheme, variance} from '../../styling';
import ActionModal from '../../modals/ActionModal';
import {ModalDescription} from '../../modals/components';
import {ButtonVariant} from '../../components';
import {colored, sized} from '../../Svg';
import ShareSvg from '../../assets/svg/colorless/share.svg';
import CopySvg from '../../assets/svg/colorless/copy.svg';
import CheckSvg from '../../assets/svg/colorless/check.svg';
import OvalRegistrationLinkSvg from '../../assets/svg/circled/registrationLink.svg';
import {showMessage} from 'react-native-flash-message';

export type RegistrationLinkModalProps = {
  onDismiss: () => void;
  code: string;
};

export default observer(({onDismiss, code}: RegistrationLinkModalProps) => {
  const {
    configuration: {values},
    clipboard,
    share,
  } = useRoot();
  const strings = useStrings();
  const theme = useTheme();
  const promoUrl = values.promoRegistrationLink.replace('{code}', code);
  const generateMessage = useCallback(() => {
    let message = `${strings['dashboard.statisticsDiscount.desc']}\n`;
    message += `${strings['dashboard.statisticsDiscount.link']} ${promoUrl} \n`;
    return message;
  }, [promoUrl, strings]);
  const shareCode = useCallback(() => {
    share.shareMessage(generateMessage());
  }, [generateMessage, share]);
  const copyLink = useCallback(() => {
    clipboard.setString(generateMessage());
    showMessage({
      message: strings['action.discountLinkCopied'],
      duration: 3000,
      type: 'success',
      icon: 'success',
      renderFlashMessageIcon: () => (
        <FlashIcon>
          <CheckIcon />
        </FlashIcon>
      ),
    });
    onDismiss();
  }, [clipboard, generateMessage, onDismiss, strings]);
  const buttons = [
    {
      text: strings['action.copy'],
      Icon: CopyIcon,
      onPress: copyLink,
      variant: ButtonVariant.Highlighted,
    },
  ];
  if (share.isSupported) {
    buttons.push({
      text: strings['action.share'],
      Icon: ShareIcon,
      onPress: shareCode,
      variant: ButtonVariant.Highlighted,
    });
  }
  return (
    <ActionModal
      buttonsDirection="column"
      Icon={OvalRegistrationLinkIcon}
      buttons={buttons}
      onDismiss={onDismiss}
      title={strings['modal.registrationLink.title']}>
      <ModalDescription>
        {strings['modal.registrationLink.desc']}
      </ModalDescription>
      <Content>
        <Input
          defaultValue={promoUrl}
          editable={false}
          placeholder={strings['purchase.modal.promo.input']}
          placeholderTextColor={theme.palette.textSecondary}
        />
      </Content>
    </ActionModal>
  );
});

const CopyIcon = colored(sized(CopySvg, 16, 18), '#fff');
const ShareIcon = colored(sized(ShareSvg, 17, 20), '#fff');
const OvalRegistrationLinkIcon = sized(OvalRegistrationLinkSvg, 60);
const CheckIcon = colored(sized(CheckSvg, 20), '#fff');
const Content = variance(View)((theme) => ({
  root: {
    marginTop: 20,
    width: '100%',
    ...theme.mediaQuery({
      1000: {
        marginTop: 40,
      },
    }),
  },
}));

const FlashIcon = variance(View)(() => ({
  root: {
    padding: 5,
  },
}));

const Input = variance(TextInput)((theme) => ({
  root: {
    ...theme.fontByWeight('bold'),
    borderWidth: 1,
    borderColor: theme.palette.border,
    width: '100%',
    height: 44,
    borderRadius: 3,
    paddingHorizontal: 15,
    paddingVertical: 5,
    backgroundColor: theme.palette.back,
    color: theme.palette.textPrimary,
  },
}));
