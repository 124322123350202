import React, {useCallback, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {
  BannerVariant,
  ButtonVariant,
  SwipeableBanner,
  translateBannerVariantToColors,
} from '../../components';
import {Image, LayoutRectangle, View} from 'react-native';
import Banner from '../../components/Banner';
import {sized} from '../../Svg';
import AdsBannerBasicIconSvg from '../../assets/svg/colorless/ads-banner-basic-icon.svg';
import {createStylesHook, useTheme, variance} from '../../styling';
import Button from '../../components/Button';
import {AdInfoItem} from '../../Advert';
import {useRoot} from '../../Root/hooks';
import {useOpenLinkCallback} from '../../Links';
import useLayout from '../../ReactNativeUtil/useLayout';
import {first} from 'lodash';
import {DESKTOP_BREAKPOINT} from '../../units/constants';
import ArrowRightSvg from '../../assets/svg/colorless/arrow.svg';
import {EXTERNAL} from '../../SpecialLocation/constants';
import {Url} from '../../units';
const VARIANT = BannerVariant.Primary;

export type AdsBannerProps = {
  height: number;
  isDesktop: boolean;
  advertItem: AdInfoItem;
};

export default observer(function AdsBanner({
  height,
  isDesktop,
  advertItem,
}: AdsBannerProps) {
  const {advertHelper, location, specialLocation} = useRoot();
  const openLink = useOpenLinkCallback();
  const theme = useTheme();
  const {id, actions, title, body, icon, spot} = advertItem;
  const kindColor = useMemo(
    () => translateBannerVariantToColors(VARIANT, theme),
    [theme],
  );
  const styles = useStyles();
  const action = first(actions);
  const onPress = useCallback(() => {
    if (!action) {
      return;
    }
    if (!action.link) {
      return advertHelper.close(id, spot, true);
    }
    if (specialLocation.parse(action.link as Url).kind === EXTERNAL) {
      openLink(action.link as Url);
    } else {
      location.open(action.link as Url);
    }
    advertHelper.close(id, spot, true);
  }, [action, advertHelper, id, location, openLink, specialLocation, spot]);

  const hasAction = action !== undefined;
  const right =
    action !== undefined && isDesktop ? (
      <View style={styles.actions}>
        <Button
          iconPosition="right"
          Icon={ArrowIcon}
          style={{width: 270}}
          variant={ButtonVariant.Highlighted}>
          {advertHelper.translate(action.title)}
        </Button>
      </View>
    ) : null;
  const translatedTitle = advertHelper.translate(title);
  const translatedDescription = advertHelper.translate(body);
  const visibleDescription = (layout?: LayoutRectangle) =>
    layout ? layout.width > 470 : true;
  const [layout, onLayout] = useLayout();

  const visibleLeftIcon = layout ? layout.width > 300 : true;
  const content = (
    <View onLayout={onLayout}>
      <Banner
        onPress={hasAction ? onPress : undefined}
        variant={VARIANT}
        title={translatedTitle}
        description={translatedDescription}
        visibleDescription={visibleDescription}
        titleStyle={styles.titleStyle}
        descriptionStyle={styles.descriptionStyle}
        leftAccessory={
          visibleLeftIcon &&
          (layout && icon !== undefined ? (
            <LeftView style={{height: 80, width: 145}}>
              <BannerImage
                source={{
                  uri: icon,
                }}
                resizeMode="contain"
              />
            </LeftView>
          ) : (
            <LeftTicketView>
              <TicketIcon color={kindColor.main} />
            </LeftTicketView>
          ))
        }
        leftContainerStyle={styles.leftContainerStyle}
        contentContainerStyle={styles.contentContainerStyle}
        rightAccessory={right}
        visibleChevron={hasAction}
        style={[styles.banner, {height}]}
        wrapperStyle={styles.bannerWrapper}
      />
    </View>
  );
  if (isDesktop && advertItem.options.canClose) {
    return (
      <SwipeableBanner
        variant={VARIANT}
        containerStyle={{height: height}}
        onDelete={() =>
          advertHelper.close(advertItem.id, advertItem.spot, false)
        }>
        {content}
      </SwipeableBanner>
    );
  }
  return <View style={{height: height}}>{content}</View>;
});

const useStyles = createStylesHook((theme) => ({
  actions: {
    flexDirection: 'row',
  },
  offset: {
    marginRight: 5,
  },
  banner: {
    borderRadius: 10,
  },
  bannerWrapper: {
    padding: 15,
  },
  leftContainerStyle: {
    paddingVertical: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  contentContainerStyle: {
    paddingLeft: 15,
  },
  titleStyle: {
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        fontSize: 20,
        lineHeight: 32,
      },
    }),
  },
  descriptionStyle: {
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        fontSize: 16,
        lineHeight: 26,
        color: theme.palette.darkText,
      },
    }),
  },
}));
const ArrowIcon = sized(ArrowRightSvg, 20, 20);
const TicketIcon = sized(AdsBannerBasicIconSvg, 48);

const BannerImage = variance(Image)(() => ({
  root: {
    width: '100%',
    height: '100%',
  },
}));

const LeftTicketView = variance(View)(() => ({
  root: {
    paddingLeft: 15,
  },
}));

const LeftView = variance(View)(() => ({
  root: {},
}));
