import {action, computed, flow, makeObservable, observable} from 'mobx';
import {
  Appearance as RNAppearance,
  ColorSchemeName,
  Dimensions,
  Platform,
  ScaledSize,
} from 'react-native';
import {define, THEME_KIND} from '../persistence';
import {
  Appearance,
  PreferredThemeKind,
  SystemThemeKind,
  ThemeKind,
} from './Appearance';
import {batchDisposers, Disposer, Service} from '../structure';
import {darkPalette, lightPalette, Theme, ThemeImpl} from '../styling';
import {AsyncReturnType} from 'type-fest';
import {bind} from '../fp';
import {WindowDimensions} from '../WindowDimensions';

export default class AppearanceService implements Appearance, Service {
  private static _themeModeTransitionMap = new Map([
    [ThemeKind.Auto, ThemeKind.Light],
    [ThemeKind.Light, ThemeKind.Dark],
    [ThemeKind.Dark, ThemeKind.Auto],
  ]);

  constructor(
    private readonly _root: {
      readonly windowDimensions: WindowDimensions;
    },
  ) {
    makeObservable(this);
  }

  @observable
  private _systemThemeKind: SystemThemeKind = colorSchemeToThemeKind(
    RNAppearance.getColorScheme(),
  );
  @observable private _preferredThemeKind: PreferredThemeKind =
    ThemeKind.Unknown;

  get systemThemeKind() {
    return this._systemThemeKind;
  }

  get preferredThemeKind() {
    return this._preferredThemeKind;
  }

  @computed get actualThemeKind() {
    const themeKind =
      this._preferredThemeKind === ThemeKind.Auto
        ? this._systemThemeKind
        : this._preferredThemeKind;
    if (themeKind === ThemeKind.Unknown) {
      return ThemeKind.Light;
    }
    return themeKind;
  }

  @computed get isDark() {
    return this.actualThemeKind === ThemeKind.Dark;
  }

  @observable.ref private _theme = this._createTheme();

  private _isUpdatingTheme = false;

  private _createTheme(
    window = Dimensions.get('window'),
    screen = Dimensions.get('screen'),
  ) {
    const isLight = this.actualThemeKind === ThemeKind.Light;
    const colors = isLight ? lightPalette : darkPalette;
    const newTheme = new ThemeImpl(colors, window, screen);
    this._isUpdatingTheme = true;
    this._setPlatformTheme(newTheme, isLight);
    // RNAppearance.setColorScheme should be async, but it's not
    setTimeout(() => {
      this._isUpdatingTheme = false;
    }, 2000);

    return newTheme;
  }

  get theme() {
    return this._theme;
  }

  private _setPlatformTheme(theme: Theme, isLight: boolean) {
    if (Platform.OS === 'web') {
      document
        .querySelector('meta[name="theme-color"]')
        ?.setAttribute('content', theme.palette.bar);
      document.body.style.color = theme.palette.bar;
      document.body.setAttribute('data-theme', isLight ? 'light' : 'dark');
    } else {
      if (
        this.preferredThemeKind === ThemeKind.Light ||
        this.preferredThemeKind === ThemeKind.Dark
      ) {
        RNAppearance.setColorScheme(isLight ? 'light' : 'dark');
      }
    }
  }

  private _load = flow(function* (this: AppearanceService) {
    const _getThemeMode: AsyncReturnType<typeof getThemeMode> =
      yield getThemeMode();
    if (!_getThemeMode.success) {
      this._preferredThemeKind = ThemeKind.Unknown;
    } else if (_getThemeMode.right === null) {
      this._preferredThemeKind = ThemeKind.Auto;
    } else {
      this._preferredThemeKind = _getThemeMode.right;
    }
    this._theme = this._createTheme();
  });

  togglePreferredThemeKind = bind(
    flow(function* (this: AppearanceService) {
      const next =
        AppearanceService._themeModeTransitionMap.get(
          this._preferredThemeKind,
        ) ?? ThemeKind.Auto;
      this.setThemeMode(next);
    }),
    this,
  );

  setThemeMode = bind(
    flow(function* (this: AppearanceService, next: ThemeKind) {
      yield putThemeMode(next);
      this._preferredThemeKind = next;
      this._theme = this._createTheme();
    }),
    this,
  );

  private _initialize() {
    const loading = this._load();
    return (() => loading.cancel()) as Disposer;
  }

  private _listenToColorSchemeChanges() {
    const callback: RNAppearance.AppearanceListener = action(
      ({colorScheme}) => {
        if (this._isUpdatingTheme) {
          return;
        }

        this._systemThemeKind = colorSchemeToThemeKind(colorScheme);
        this._theme = this._createTheme();
      },
    );
    const subscription = RNAppearance.addChangeListener(callback);
    return (() => subscription.remove()) as Disposer;
  }

  @action.bound private _onSizeChange(update: {
    window: ScaledSize;
    screen: ScaledSize;
  }) {
    this._theme = this._createTheme(update.window, update.screen);
  }

  private _listenToDimensionsChanges() {
    return this._root.windowDimensions.updates.listen(this._onSizeChange);
  }

  subscribe() {
    return batchDisposers(
      this._initialize(),
      this._listenToColorSchemeChanges(),
      this._listenToDimensionsChanges(),
    );
  }
}

const [getThemeMode, putThemeMode] = define<ThemeKind>(THEME_KIND);

const colorSchemeToThemeKind = (scheme: ColorSchemeName): SystemThemeKind => {
  switch (scheme) {
    case 'light':
      return ThemeKind.Light;
    case 'dark':
      return ThemeKind.Dark;
  }
  return ThemeKind.Unknown;
};
