import React, {useCallback, useRef} from 'react';
import {View} from 'react-native';
import {observer} from 'mobx-react-lite';

import useOutside from '../../../universal/utils/react/hook/useOutside';
import Menu from './Menu';
import {useRoot, useStrings} from '../../Root/hooks';
import ActionModal from '../../modals/ActionModal';
import WarningSvg from '../../assets/svg/circled/warning.svg';
import {ButtonColor, ButtonVariant} from '../../components';
import {useBoolean} from '../../hooks';
import DropdownButton from './DropdownButton';

export default observer(() => {
  const {auth} = useRoot();
  const strings = useStrings();
  const [visibleMenu, showMenu, hideMenu] = useBoolean(false);
  const [visibleLogoutModal, showLogoutModal, hideLogoutModal] =
    useBoolean(false);
  const wrapperRef = useRef<View>(null);
  useOutside(wrapperRef, hideMenu);
  const openLogoutModal = useCallback(async () => {
    hideMenu();
    showLogoutModal();
  }, [hideMenu, showLogoutModal]);
  const logout = useCallback(() => {
    auth.signOut();
    hideMenu();
    hideLogoutModal();
  }, [auth, hideLogoutModal, hideMenu]);
  return (
    <View ref={wrapperRef}>
      <DropdownButton
        showMenu={showMenu}
        hideMenu={hideMenu}
        visibleMenu={visibleMenu}
      />
      {visibleMenu && <Menu logout={openLogoutModal} hide={hideMenu} />}
      {visibleLogoutModal && (
        <ActionModal
          onDismiss={hideLogoutModal}
          buttons={[
            {
              text: strings['action.ok'],
              onPress: logout,
              color: ButtonColor.Default,
              variant: ButtonVariant.Highlighted,
            },
          ]}
          title={strings['menu.signOut']}
          Icon={WarningSvg}>
          {strings['menu.signOutPrompt']}
        </ActionModal>
      )}
    </View>
  );
});
