import React, {useCallback} from 'react';
import {View, Text, TouchableWithoutFeedback} from 'react-native';
import {observer} from 'mobx-react-lite';
import {useRoot, useStrings} from '../Root/hooks';
import {Button, ButtonVariant} from '../components';
import BtcToCoinSvg from '../assets/svg/colored/btcToCoin.svg';
import {CommonActions} from '@react-navigation/native';
import CoinSvg from '../assets/svg/colored/coin.svg';
import {openLink} from '../Links';
import {PageScrollView} from '../containers';
import {useStyles} from '../styling';
import {sized} from '../Svg';
import DesktopWebAppHeader from '../DesktopWebAppHeader/DesktopWebAppHeader';
import {DASHBOARD} from '../Navigation/RootStack/constants';
import {RootStackBindingProps} from '../Navigation/RootStack/RootStackBindingProps';

export type PaymentSuccessScreenProps = RootStackBindingProps<'PaymentSuccess'>;

export default observer((props: PaymentSuccessScreenProps) => {
  const {navigation, route} = props;
  const styles = useStyles((theme) => ({
    root: {
      flexGrow: 1,
      flexShrink: 1,
      backgroundColor: theme.palette.background,
    },
    container: {
      justifyContent: 'center',
    },
    panel: {
      margin: 20,
      overflow: 'hidden',
      borderRadius: 4,
      borderWidth: 1,
      borderColor: theme.palette.border,
      backgroundColor: theme.palette.background,
    },
    image: {
      marginTop: 40,
      alignSelf: 'center',
    },
    title: {
      marginTop: 20,
      marginHorizontal: 15,
      ...theme.fontByWeight('bold'),
      fontSize: 22,
      lineHeight: 32,
      textAlign: 'center',
      letterSpacing: 0.0628572,
      color: theme.palette.textPrimary,
    },
    description: {
      marginTop: 10,
      marginHorizontal: 15,
      ...theme.fontByWeight(),
      fontSize: 14,
      lineHeight: 22,
      textAlign: 'center',
      letterSpacing: 0.04,
    },
    link: {
      color: theme.palette.secondary,
    },
    section: {
      marginTop: 30,
      padding: 15,
      borderTopWidth: 1,
      borderTopColor: theme.palette.border,
      backgroundColor: theme.palette.background,
    },
    dashboardButton: {
      marginTop: 15,
    },
    button: {
      cursor: 'pointer',
    },
  }));
  const strings = useStrings();
  const amount = route.params.amount;
  const {
    auth: {fernetToken},
    socketApi,
    configuration,
  } = useRoot();
  const {oauthIssuer} = configuration.values;
  const openAffiliate = useCallback(async () => {
    if (!fernetToken) {
      return;
    }
    const response = await socketApi.getTemporaryToken({
      token: fernetToken,
    });
    const loginUrl = `${oauthIssuer}login/token/${response.login}/?next=/app/about`;
    await openLink(loginUrl);
  }, [socketApi, fernetToken, oauthIssuer]);
  const openDashboard = useCallback(() => {
    navigation.dispatch(CommonActions.reset(DASHBOARD));
  }, [navigation]);
  return (
    <View style={styles.root}>
      <DesktopWebAppHeader />
      <PageScrollView contentContainerStyle={styles.container}>
        <View style={styles.panel}>
          <BtcToCoinSvg style={styles.image} />
          <Text style={styles.title}>{strings['paymentSuccess.title']}</Text>
          <Text style={styles.description}>
            {strings['paymentSuccess.description'].replace(
              '{amount}',
              amount.toFixed(8),
            )}
          </Text>
          <View style={styles.section}>
            <TouchableWithoutFeedback onPress={openAffiliate}>
              <Button Icon={CoinIcon} style={styles.button}>
                {strings['paymentSuccess.openButton']}
              </Button>
            </TouchableWithoutFeedback>
            <TouchableWithoutFeedback onPress={openDashboard}>
              <Button
                style={[styles.dashboardButton, styles.button]}
                variant={ButtonVariant.Highlighted}>
                {strings['paymentSuccess.openDashboard']}
              </Button>
            </TouchableWithoutFeedback>
          </View>
        </View>
      </PageScrollView>
    </View>
  );
});

const CoinIcon = sized(CoinSvg, 16);
