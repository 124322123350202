import {AdvertisingId, AdvertisingIdentifier} from './AdvertisingIdentifier';
import {GlobalError, UNKNOWN_ERROR} from '../Error';
import {Either, error, success} from '../fp';

import {
  getAdvertisingId,
  requestTrackingPermissionsAsync,
} from '../TrackingTransparency';
import {ErrorRepository} from '../ErrorRepository';

export default class AdvertisingIdentifierImpl
  implements AdvertisingIdentifier
{
  constructor(
    private readonly _root: {
      readonly errorRepository: ErrorRepository;
    },
  ) {}
  async getInfo(): Promise<Either<AdvertisingId | null, GlobalError>> {
    try {
      const {granted} = await requestTrackingPermissionsAsync();
      if (!granted) {
        return success(null);
      }
      const id = getAdvertisingId();
      return success(id as AdvertisingId);
    } catch (raw) {
      return error(
        this._root.errorRepository.create({kind: UNKNOWN_ERROR, raw}),
      );
    }
  }
}
