import React from 'react';
import {observer} from 'mobx-react-lite';
import {Text, View} from 'react-native';
import {PageScrollView} from '../containers';
import {useStyles} from '../styling';
import {useStrings} from '../Root/hooks';
import WorkerContainer from './WorkerContainer';
import DesktopWebAppHeader from '../DesktopWebAppHeader/DesktopWebAppHeader';
import {ReadonlyDeep} from 'type-fest';
import {WorkerEntity} from '../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

export interface WorkerDetailsProps {
  goToScheduler: () => void;
  goToRenew: () => void;
  onDeleted: () => void;
  isLoading: boolean;
  worker: ReadonlyDeep<WorkerEntity> | undefined;
  visibleRenewButton: boolean;
}

export default observer((props: WorkerDetailsProps) => {
  const {
    goToScheduler,
    goToRenew,
    onDeleted,
    isLoading,
    worker,
    visibleRenewButton,
  } = props;
  const strings = useStrings();
  const styles = useStyles((theme) => ({
    container: {
      paddingVertical: 20,
      flexGrow: 1,
      paddingHorizontal: 5,
      ...theme.mediaQuery({
        1000: {
          paddingHorizontal: 0,
        },
      }),
    },
  }));
  const insets = useSafeAreaInsets();
  return (
    <>
      <DesktopWebAppHeader />
      <PageScrollView
        contentContainerStyle={[
          styles.container,
          {paddingBottom: insets.bottom},
        ]}>
        {isLoading ? (
          <PlaceholderInfo text={strings['workerDetails.loading']} />
        ) : worker ? (
          <WorkerContainer
            onDeleted={onDeleted}
            onSchedulePress={goToScheduler}
            onRenewPress={goToRenew}
            worker={worker}
            visibleRenewButton={visibleRenewButton}
          />
        ) : (
          <PlaceholderInfo text={strings['workerDetails.workerNotFound']} />
        )}
      </PageScrollView>
    </>
  );
});

interface PlaceholderInfoProps {
  text: string;
}

const PlaceholderInfo = observer(({text}: PlaceholderInfoProps) => {
  const styles = useStyles((theme) => ({
    root: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    text: {
      fontSize: 16,
      color: theme.palette.textSecondary,
      ...theme.fontByWeight('400'),
    },
  }));
  return (
    <View style={styles.root}>
      <Text style={styles.text}>{text}</Text>
    </View>
  );
});
