import React, {useCallback} from 'react';
import {
  StyleSheet,
  FlatListProps,
  TouchableWithoutFeedback,
  View,
  ViewProps,
  Text,
} from 'react-native';
import {observer} from 'mobx-react-lite';
import {useStrings} from '../Root/hooks';
import RecoveryAccessSvg from '../assets/svg/circled/recoveryAccess.svg';
import {Container} from '../components/Grid';
import {
  TemporarySubscriptionView,
  PermanentSubscriptionView,
} from '../SubscriptionView';
import SwitchFarmHeader from '../components/SwitchFarmScreenHeader';
import {PageFlatList} from '../containers';
import {RestoredSubscription} from '../farmApi';
import {useStyles} from '../styling';
import {ReadonlyDeep} from 'type-fest';
import {createNullableContext, useForcedContext} from '../context';
import {expr} from 'mobx-utils';
import {Button} from '../components';
import DesktopWebAppHeader from '../DesktopWebAppHeader/DesktopWebAppHeader';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

export interface AccessRecoveryScreenProps {
  getSubscriptions: () => ReadonlyDeep<RestoredSubscription[]> | undefined;
  getIsRefreshing: () => boolean;
  onRefresh: () => void;
  onRestore: (subscription: RestoredSubscription) => void;
  onRegister?: () => void;
  registerShown?: boolean;
}

export default observer((props: AccessRecoveryScreenProps) => {
  const {getSubscriptions, getIsRefreshing, onRefresh, registerShown} = props;
  const data = expr(getSubscriptions);
  const refreshing = expr(getIsRefreshing);
  const ListFooterComponent = registerShown ? ListFooter : undefined;
  const insets = useSafeAreaInsets();
  return (
    <AccessRecoveryContext.Provider value={props}>
      <View style={styles.root}>
        <DesktopWebAppHeader />
        <PageFlatList
          style={styles.root}
          contentContainerStyle={{
            paddingBottom: insets.bottom,
          }}
          ListHeaderComponent={ListHeader}
          ListHeaderComponentStyle={styles.header}
          ListFooterComponent={ListFooterComponent}
          ListEmptyComponent={data && ListEmpty}
          data={data}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          refreshing={refreshing}
          onRefresh={onRefresh}
        />
      </View>
    </AccessRecoveryContext.Provider>
  );
});

const styles = StyleSheet.create({
  header: {
    marginBottom: 10,
  },
  item: {
    marginBottom: 10,
    marginHorizontal: 20,
  },
  root: {
    flexGrow: 1,
    flexShrink: 1,
  },
  container: {
    flexGrow: 1,
  },
});

type ListProps = FlatListProps<RestoredSubscription>;

const renderItem: ListProps['renderItem'] = ({item}) => (
  <Item style={styles.item} item={item} />
);

const keyExtractor: ListProps['keyExtractor'] = (item) =>
  String(item.accountId);

const ListHeader = observer(() => {
  const strings = useStrings();
  return (
    <Container>
      <SwitchFarmHeader
        title={strings['recoveryAccess.title']}
        description={strings['recoveryAccess.description']}
        Icon={RecoveryAccessSvg}
      />
    </Container>
  );
});

export interface ItemProps extends ViewProps {
  item: RestoredSubscription;
}

export const Item = observer(({item, ...rest}: ItemProps) => {
  const {onRestore} = useForcedContext(AccessRecoveryContext);
  const onPress = useCallback(() => onRestore(item), [item, onRestore]);
  return (
    <Container>
      <TouchableWithoutFeedback onPress={onPress}>
        {item.type === 'Temporary' ? (
          <TemporarySubscriptionView {...item} {...rest} />
        ) : (
          <PermanentSubscriptionView {...item} {...rest} />
        )}
      </TouchableWithoutFeedback>
    </Container>
  );
});

const ListEmpty = observer(() => {
  const styles = useStyles((theme) => ({
    root: {
      minHeight: 200,
      justifyContent: 'center',
    },
    text: {
      ...theme.fontByWeight(),
      fontSize: 15,
      lineHeight: 22,
      letterSpacing: 0.0609524,
      color: theme.palette.textSecondary,
      textAlign: 'center',
    },
  }));
  const strings = useStrings();
  return (
    <View style={styles.root}>
      <Text style={styles.text}>{strings['common.noData']}</Text>
    </View>
  );
});

const ListFooter = observer(() => {
  const {onRegister} = useForcedContext(AccessRecoveryContext);
  const strings = useStrings();
  return (
    <TouchableWithoutFeedback onPress={onRegister}>
      <Button>{strings['signUp.newRegister']}</Button>
    </TouchableWithoutFeedback>
  );
});

const AccessRecoveryContext =
  createNullableContext<AccessRecoveryScreenProps>();
