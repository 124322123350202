import React, {ReactNode, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {
  LayoutRectangle,
  Pressable,
  StyleProp,
  Text,
  TextStyle,
  View,
  ViewProps,
  ViewStyle,
} from 'react-native';
import {sized} from '../Svg';
import ChevronRightSvg from '../assets/svg/colorless/chevronRight.svg';

import {createStylesHook, Theme, useTheme, variance} from '../styling';
import useLayout from '../ReactNativeUtil/useLayout';
import Markdown from './Markdown';
import {Hoverable} from 'react-native-web-hooks';

export enum BannerVariant {
  Primary,
  Success,
  Info,
  Error,
  Purple,
}

export type InfoBannerProps = ViewProps & {
  leftAccessory?: React.ReactNode;
  leftContainerStyle?: StyleProp<ViewStyle>;
  rightAccessory?: React.ReactNode;
  contentContainerStyle?: StyleProp<ViewStyle>;
  onPress?: () => void;
  title?: string;
  description?: string;
  visibleDescription?: (layout: LayoutRectangle | undefined) => boolean;
  variant?: BannerVariant;
  visibleChevron?: boolean;
  wrapperStyle?: StyleProp<ViewStyle>;
  titleStyle?: TextStyle;
  descriptionStyle?: TextStyle;
};

export default observer(
  ({
    leftAccessory,
    leftContainerStyle,
    rightAccessory,
    onPress,
    contentContainerStyle,
    title,
    description,
    visibleDescription,
    style,
    variant = BannerVariant.Info,
    visibleChevron = true,
    wrapperStyle,
    titleStyle,
    descriptionStyle,
  }: InfoBannerProps) => {
    const styles = useStyles();
    const [layout, onLayout] = useLayout();
    const theme = useTheme();
    const {main} = useMemo(
      () => translateBannerVariantToColors(variant, theme),
      [variant, theme],
    );
    const iconColor = main;
    const borderColor = main;
    const backgroundColor = theme.chroma(main).alpha(0.1).hex();
    const rules = {
      paragraph: (key: number, _children: ReactNode) => {
        return (
          <Text key={key} numberOfLines={3} ellipsizeMode="tail">
            {_children}
          </Text>
        );
      },
    };
    const _visibleDescription = visibleDescription?.(layout) ?? true;
    return (
      <View style={style} onLayout={onLayout}>
        <BackgroundView>
          <Hoverable>
            {(isHoverable) => (
              <Pressable
                disabled={onPress === undefined}
                onPress={onPress}
                style={[
                  styles.root,
                  {borderColor, backgroundColor},
                  style,
                  wrapperStyle,
                ]}>
                {leftAccessory && (
                  <View style={[styles.left, leftContainerStyle]}>
                    {leftAccessory}
                  </View>
                )}
                <View style={[styles.content, contentContainerStyle]}>
                  {title !== undefined && (
                    <Markdown
                      rules={rules}
                      styles={{
                        paragraph: {...styles.title, ...titleStyle},
                      }}>
                      {title}
                    </Markdown>
                  )}
                  {description !== undefined && _visibleDescription && (
                    <Markdown
                      rules={rules}
                      styles={{
                        paragraph: {...styles.paragraph, ...descriptionStyle},
                      }}>
                      {description}
                    </Markdown>
                  )}
                </View>
                {rightAccessory ? (
                  <View style={styles.right}>{rightAccessory}</View>
                ) : visibleChevron ? (
                  <View style={styles.right}>
                    <View
                      style={[
                        styles.chevron,
                        isHoverable && {
                          backgroundColor: main,
                          borderColor: main,
                        },
                      ]}>
                      <ChevronIcon
                        color={
                          isHoverable ? theme.palette.textPrimary : iconColor
                        }
                      />
                    </View>
                  </View>
                ) : null}
              </Pressable>
            )}
          </Hoverable>
        </BackgroundView>
      </View>
    );
  },
);

const BackgroundView = variance(View)((theme) => ({
  root: {
    flex: 1,
    borderRadius: 15,
    backgroundColor: theme.palette.background,
  },
}));

const ChevronIcon = sized(ChevronRightSvg, 5, 10);

const useStyles = createStylesHook((theme) => ({
  root: {
    flex: 1,
    borderWidth: 2,
    borderRadius: 15,
    flexDirection: 'row',
  },
  left: {
    justifyContent: 'center',
    paddingRight: 20,
    paddingLeft: 15,
    paddingVertical: 5,
  },
  right: {
    justifyContent: 'center',
    paddingLeft: 15,
    paddingRight: 15,
    paddingVertical: 5,
  },
  content: {
    flexShrink: 1,
    flexGrow: 1,
    justifyContent: 'center',
    paddingVertical: 5,
  },
  chevron: {
    borderWidth: 1,
    borderColor: theme.palette.border,
    backgroundColor: theme.palette.background,
    borderRadius: 10,
    width: 24,
    height: 32,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 12,
    lineHeight: 18,
    marginTop: 0,
    marginBottom: 0,
    ...theme.mediaQuery({
      376: {
        fontSize: 14,
        lineHeight: 22,
      },
    }),
  },
  paragraph: {
    ...theme.fontByWeight('400'),
    color: theme.palette.textPrimary,
    fontSize: 12,
    lineHeight: 18,
    marginTop: 0,
    marginBottom: 0,
    ...theme.mediaQuery({
      376: {
        fontSize: 14,
        lineHeight: 18,
      },
    }),
  },
  infoText: {
    ...theme.fontByWeight('400'),
    color: theme.palette.textPrimary,
    fontSize: 12,
    lineHeight: 18,
    ...theme.mediaQuery({
      376: {
        fontSize: 14,
        lineHeight: 22,
      },
    }),
  },
}));

export const translateBannerVariantToColors = (
  kind: BannerVariant,
  theme: Theme,
) => {
  switch (kind) {
    case BannerVariant.Info:
      return {
        main: theme.palette.info,
        additional: theme.palette.additionalInfo,
      };
    case BannerVariant.Purple:
      return {
        main: theme.palette.purple,
        additional: theme.palette.additionalPurple,
      };
    case BannerVariant.Success:
      return {
        main: theme.palette.success,
        additional: theme.palette.additionalSuccess,
      };
    case BannerVariant.Error:
      return {
        main: theme.palette.error,
        additional: theme.palette.error,
      };
    case BannerVariant.Primary:
    default:
      return {
        main: theme.palette.primary,
        additional: theme.palette.additionalPrimary,
        close: '#B3B3B3',
      };
  }
};
