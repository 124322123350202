import React from 'react';
import {Platform, View} from 'react-native';

import {useStyles} from '../styling';
import {Picker} from '@react-native-picker/picker';

export type TimezonePickerProps<V = string | number> = {
  items: {
    value: V;
    label: string;
  }[];
  value: V;
  onChange: (value: V) => void;
};

export function TimezonePicker<V>(props: TimezonePickerProps<V>) {
  const {onChange, items, value, ...rest} = props;

  const styles = useStyles((theme) => ({
    container: Platform.select({
      native: {
        borderWidth: 1,
        borderColor: theme.palette.border,
        backgroundColor: theme.palette.background,
        borderRadius: 2,
        minWidth: 180,
      },
      default: {},
    }),
    input: Platform.select({
      web: {
        fontSize: 14,
        color: theme.palette.textPrimary,
        justifyContent: 'center',
        borderWidth: 1,
        borderColor: theme.palette.border,
        backgroundColor: theme.palette.background,
        padding: 10,
        borderRadius: 2,
      },
      default: {
        color: theme.palette.textPrimary,
      },
    }),
  }));

  return (
    <View {...rest}>
      <View style={styles.container}>
        <Picker
          style={styles.input}
          dropdownIconColor="#D0D0D0"
          selectedValue={value}
          onValueChange={(itemValue) => onChange(itemValue)}>
          {items.map((item) => (
            <Picker.Item label={item.label} value={item.value} />
          ))}
        </Picker>
      </View>
    </View>
  );
}
