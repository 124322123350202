import React, {useCallback, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {
  BannerVariant,
  SwipeableBanner,
  translateBannerVariantToColors,
} from '../../components';
import {Image, LayoutRectangle, TouchableOpacity, View} from 'react-native';
import Banner from '../../components/Banner';
import {sized} from '../../Svg';
import AdsBannerBasicIconSvg from '../../assets/svg/colorless/ads-banner-basic-icon.svg';
import {createStylesHook, useTheme, variance} from '../../styling';
import {AdInfoItem} from '../../Advert';
import {useRoot} from '../../Root/hooks';
import {useOpenLinkCallback} from '../../Links';
import useLayout from '../../ReactNativeUtil/useLayout';
import {first} from 'lodash';
import CloseSvg from '../../assets/svg/colorless/close.svg';
import {Url} from '../../units';
import {EXTERNAL} from '../../SpecialLocation/constants';

const VARIANT = BannerVariant.Primary;

export type AdsBannerProps = {
  height: number;
  isDesktop: boolean;
  advertItem: AdInfoItem;
};

export default observer(function AdsMobileBanner({
  height,
  isDesktop,
  advertItem,
}: AdsBannerProps) {
  const {advertHelper, specialLocation, location} = useRoot();
  const openLink = useOpenLinkCallback();
  const theme = useTheme();
  const {id, actions, title, icon, spot} = advertItem;
  const kindColor = useMemo(
    () => translateBannerVariantToColors(VARIANT, theme),
    [theme],
  );
  const styles = useStyles();
  const action = first(actions);
  const onPress = useCallback(() => {
    if (!action) {
      return;
    }
    if (!action.link) {
      return advertHelper.close(id, spot, true);
    }
    if (specialLocation.parse(action.link as Url).kind === EXTERNAL) {
      openLink(action.link as Url);
    } else {
      location.open(action.link as Url);
    }
    advertHelper.close(id, spot, true);
  }, [action, advertHelper, id, location, openLink, specialLocation, spot]);

  const closeBanner = useCallback(() => {
    advertHelper.close(id, spot, true);
  }, [advertHelper, id, spot]);

  const hasAction = action !== undefined;
  const right =
    action !== undefined ? (
      <ActionsWrapper onPress={closeBanner}>
        <Actions>
          <CloseIcon color={'rgba(117, 117, 117, 0.70)'} />
        </Actions>
      </ActionsWrapper>
    ) : null;
  const translatedTitle = advertHelper.translate(title);

  const visibleDescription = (layout?: LayoutRectangle) =>
    layout ? layout.width > 470 : true;
  const [layout, onLayout] = useLayout();
  const visibleLeftIcon = layout ? layout.width > 300 : true;
  const content = (
    <View onLayout={onLayout}>
      <Banner
        onPress={hasAction ? onPress : undefined}
        variant={VARIANT}
        title={translatedTitle}
        visibleDescription={visibleDescription}
        style={[styles.banner, {height}]}
        wrapperStyle={styles.wrapperStyle}
        leftAccessory={
          visibleLeftIcon &&
          (layout && icon !== undefined ? (
            <LeftView>
              <BannerImage
                source={{
                  uri: icon,
                }}
                resizeMode="contain"
              />
            </LeftView>
          ) : (
            <LeftTicketView>
              <TicketIcon color={kindColor.main} />
            </LeftTicketView>
          ))
        }
        leftContainerStyle={styles.leftContainerStyle}
        contentContainerStyle={styles.contentContainerStyle}
        rightAccessory={right}
        visibleChevron={hasAction}
      />
    </View>
  );
  if (isDesktop && advertItem.options.canClose) {
    return (
      <SwipeableBanner
        variant={VARIANT}
        containerStyle={{height: height}}
        onDelete={() =>
          advertHelper.close(advertItem.id, advertItem.spot, false)
        }>
        {content}
      </SwipeableBanner>
    );
  }
  return <View style={{height: height}}>{content}</View>;
});
const CloseIcon = sized(CloseSvg, 14, 14);
const useStyles = createStylesHook((theme) => ({
  offset: {
    marginRight: 5,
  },
  leftContainerStyle: {
    paddingVertical: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  contentContainerStyle: {
    paddingLeft: 15,
  },
  banner: {
    borderWidth: 0,
    borderRadius: 0,
  },
  wrapperStyle: {
    borderBottomWidth: 1,
    borderColor: theme.palette.border,
  },
}));

const TicketIcon = sized(AdsBannerBasicIconSvg, 48);
const Actions = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    color: 'rgba(117, 117, 117, 0.70)',
  },
}));
const ActionsWrapper = variance(TouchableOpacity)(() => ({
  root: {
    width: 40,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: -15,
  },
}));
const BannerImage = variance(Image)(() => ({
  root: {
    width: '100%',
    height: '100%',
  },
}));

const LeftTicketView = variance(View)(() => ({
  root: {
    paddingLeft: 15,
  },
}));

const LeftView = variance(View)(() => ({
  root: {height: 40, minWidth: 70, marginLeft: 10},
}));
